import React from 'react';
import getRichMark from '../../helpers/richmark';

const Hod = (props) => {
  const { hardcoded, data, url, img } = props;
  if (hardcoded) {
    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: hardcoded }} />;
  }

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={getRichMark(data, url, img)}
    />
  );
};

export default Hod;
