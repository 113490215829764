import React from 'react';
import bclist from '../../constants/breadcrumbs';
import urls from '../../constants/urls';
import Pawlink from '../PawLink/PawLink';
import './NeoAesir.scss';

class NeoAesir extends React.PureComponent {
  render() {
    const { pageName, locale, options, title } = this.props;

    const mapSlider = array =>
      array.map((element, index) => (
        <React.Fragment key={element.breadcrumbText}>
          <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            <span> {'>'} </span>
            <Pawlink
              type="named"
              destination={element.bcDestination}
              parameters={element.bcDestinationOptions}
              translatedParameters={element.bcTranslatedParameters}
              className="bold-item"
              itemprop
            >
              <span itemProp="name"> {element.breadcrumbText} </span>
            </Pawlink>
            <meta itemProp="position" content={index + 2} />
          </li>
        </React.Fragment>
      ));

    const lang = locale;
    const output = [];

    let currentbc = pageName;
    let lastbc = true;
    while (currentbc !== 'index') {
      const currentPage = bclist[currentbc].page;
      const isPage = urls.pages[currentPage] !== undefined;
      const isTemplate = urls.templates[currentPage] !== undefined;
      const isTemplateMultilang = urls.templates_multilang[currentPage] !== undefined;
      let bcDestination = '';
      let bcDestinationOptions = '';
      let breadcrumbText = '';
      let bcTranslatedParameters = true;

      // If is the last item of the breadcrumb
      if (lastbc) {
        if (title) {
          breadcrumbText = title;
        } else {
          breadcrumbText = bclist[currentbc].name.filter(a => Object.keys(a)[0] === lang)[0][lang];
        }
        if (currentPage) bcDestination = currentPage;
        else bcDestination = pageName;
        bcDestinationOptions = options;
        if (isTemplateMultilang) bcTranslatedParameters = true;
        // Middle items of the breadcrumb
      } else {
        breadcrumbText = bclist[currentbc].name.filter(a => Object.keys(a)[0] === lang)[0][lang];
        if (isPage) {
          bcDestination = currentPage;
        }
        if (isTemplate) {
          bcDestination = currentPage;
          bcDestinationOptions = bclist[currentbc].options;
        }
        if (isTemplateMultilang) {
          bcDestination = currentPage;
          bcDestinationOptions = bclist[currentbc].options;
          bcTranslatedParameters = false;
        }
      }
      output.unshift({
        breadcrumbText,
        bcDestination,
        bcDestinationOptions,
        bcTranslatedParameters,
      });
      if (bclist[currentbc].getParent) {
        currentbc = bclist[currentbc].getParent(options, lang);
      } else {
        currentbc = bclist[currentbc].parent;
      }
      lastbc = false;
    }
    return (
      <div className="breadcrumb-page">
        <ol itemScope itemType="http://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            <Pawlink type="named" destination="index" itemprop>
              <span itemProp="name" itemType="https://schema.org/itemListElement">
                PortAventura World
              </span>
            </Pawlink>
            <meta itemProp="position" content="1" />
          </li>
          {mapSlider(output)}
        </ol>
      </div>
    );
  }
}

export default NeoAesir;
