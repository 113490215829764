import React from 'react';
import HelaImage from './HelaImage/HelaImage';
import './hela.scss';

const Hela = (props) => {
  const {
    data: {
      hotels,
    },
    locale,
  } = props;

  const mapSlider = array => array.map(element => (
    <HelaImage locale={locale} hotel={element} key={element.hotelCardData.id} />
  ));

  return (
    <div className="top-hotel-container">
      <div className="top-hotel-sliders">
        {mapSlider(hotels)}
      </div>
    </div>
  );
};


export default Hela;
