import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Buttons from '../../Buttons/Buttons';
import './helaCard.scss';
import RatingStars from '../../RatingStars/RatingStars';

import tt from '../../../helpers/translation';
import Pawlink from '../../PawLink/PawLink';

const HelaCard = (props) => {
  const {
    hotelCardData: {
      styles,
      title,
      stars,
      whiteButtonURL,
      description,
      orangeButtonURL,
      orangeButtonText,
    },
    locale,
  } = props;

  return (
    <div className="HotelCard-main" style={styles}>
      <div className="hotelCard-wrapper">
        <Pawlink type="unknown" destination={whiteButtonURL} className="hotelCard-title">
          <h3>{title}</h3>
        </Pawlink>
        {stars && (
          <div className="react-stars">
            <RatingStars count={stars} color="#5e5e5e" />
          </div>
        )}
        <div className="hotelCard-text" dangerouslySetInnerHTML={{ __html: description }} />
        {whiteButtonURL && (
          <div className="level">
            <Buttons
              link={whiteButtonURL}
              size="small"
              color="white"
              text={tt('DESCUBRIR', locale)}
            />
            <Buttons link={orangeButtonURL} size="small" color="orange" text={orangeButtonText} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HelaCard;
