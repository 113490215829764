import React from 'react';

import './fenrir.scss';

export const Fenrir = props => {
  const {
    data: { list },
  } = props;

  if (props.data.list) {
    return <div className="text-inner" dangerouslySetInnerHTML={{ __html: list }} />;
  } else {
    return null;
  }
};
