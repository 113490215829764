/**
 *
 * @param {'es'|'ca'|'fr'|'de'|'en'|'ru'|'it'} locale
 * @param {'tickets'|'hotels'|'club-funnel'} funnel
 * @param {number} type
 * @returns string
 */
const buildFunnelUrl = (locale, funnel, type) => {
  const productType = type ? `?type=${type}` : '';
  return `https://book.portaventuraworld.com/${locale}/${funnel}${productType}`;
};

export default buildFunnelUrl;
