import React from 'react';
import PropTypes from 'prop-types';
import './Buttons2.scss';
import PawLink from '../PawLink/PawLink';

/**
 * Return a Button.
 * @property {boolean} noRel - The element.
 * @property {boolean} blank - The element.
 * @property {function} onClick - The element.
 * @property {string} link - The element.
 * @property {string} text - The element.
 * @property {string} color - options (white | orange | Blancotransparente | BlancoNovedades | Blanco | Naranja | SALE ).
 * @property {string} size - options (small | alone ).
 * @property {string} exception - options (margin_top  | mini_button | mapa | special | header | disabled).
 * @return {Component} componente Button
 */

const Button = (props) => {
  const {
    noRel,
    link,
    size,
    color,
    text,
    blank,
    exception,
    onClick,
    named,
    alineado,
    noLink,
  } = props;

  let classButton;
  let sizebutton;
  let colorbutton;
  if (
    color ===
    ![
      'white',
      'orange',
      'Blancotransparente',
      'Blanco',
      'Naranjatransparente',
      'Naranja',
      'SALE',
      'gris-banner',
      'transparente',
    ].includes(color)
  ) {
    console.log('No se ha introducido ningún valor de color correcto');
    console.log(color, size, exception);
  }
  if (size === !['alone', 'small'].includes(size)) {
    console.log('No se ha introducido ningún valor de tamaño correcto');
    console.log(color, size, exception);
  }

  if (size === 'alone') {
    sizebutton = 'alone_button';
  } else {
    sizebutton = 'small_button';
  }

  if (color === 'SALE') {
    colorbutton = 'oferta';
  } else {
    colorbutton = color;
  }

  if (exception) {
    classButton = `${sizebutton} ${colorbutton} ${exception}`;
  } else {
    classButton = `${sizebutton} ${colorbutton}`;
  }

  let isNorel;
  let isOnClick;

  if (onClick) {
    isOnClick = (
      <div className={`button2 ${classButton}`} onClick={onClick}>
        {text}
      </div>
    );
  } else if (noLink) {
    isOnClick = <div className={`button2 ${classButton} notClicked`}>{text}</div>;
  } else if (named) {
    isOnClick = (
      <PawLink
        type="named"
        destination={link}
        parameters={['']}
        className={`button2 ${classButton}`}
      >
        {text}
      </PawLink>
    );
  }
  if (noRel) {
    isNorel = (
      <PawLink type="unknown" destination={link} rel={noRel} className={`button2 ${classButton}`}>
        {text}
      </PawLink>
    );
  } else if (blank) {
    isNorel = (
      <PawLink type="anchor" destination={link} rel={noRel} className={`button2 ${classButton}`}>
        {text}
      </PawLink>
    );
  } else {
    isNorel = (
      <PawLink type="unknown" destination={link} className={`button2 ${classButton}`}>
        {text}
      </PawLink>
    );
  }

  return (
    <div className={`button2-container ${alineado || ''}`}>
      {onClick || named || noLink ? isOnClick : isNorel}
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  noRel: PropTypes.string,
  blank: PropTypes.boolean,
  exception: PropTypes.string,
  onClick: PropTypes.function,
  named: PropTypes.boolean,
};

export default Button;
