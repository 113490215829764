import React from 'react';
import PropTypes from 'prop-types';
import './TitleH4single.scss';

const TitleH4single = props => {
  const { title, description } = props;
  return (
    <div className="SimplecompTitle-container">
      <h4 className="SimplecompTitle-title" dangerouslySetInnerHTML={{ __html: title }} />
      {description && (
        <p className="SimplecompTitle-subtitle" dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  );
};

TitleH4single.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleH4single;
