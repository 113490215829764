const getRichMark = (data, url, image) => {
  let richMark;
  switch (data.rich_markup_type) {
    case 'rides':
      richMark = {
        '@context': 'https://schema.org',
        '@type': ['TouristAttraction', 'AmusementPark'],
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,
        image: data.imgRich || image,
        isAccessibleForFree: false,
        currenciesAccepted: 'EUR',
        paymentAccepted: 'Cash, Credit Card',
        priceRange: 'EUR',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-Seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },
      };
      break;
    case 'restaurant':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Restaurant',
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,
        image: data.imgRich || image,
        priceRange: 'EUR',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },
      };
      break;
    case 'product':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: data.og_title,
        description: data.og_description,
        brand: 'Portaventura World',
        image: data.imgRich || image,
        offers: {
          '@type': 'Offer',
          url: `https://www.portaventuraworld.com/${url}`,
          priceCurrency: 'EUR',
          price: data.price,
          priceValidUntil: '31-12-2019',
          availability: 'http://schema.org/LimitedAvailability',
        },
      };
      break;
    case 'theme_park':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'AmusementPark',
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,

        email: 'info@portaventura.es',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },
        geo: { '@type': 'GeoCoordinates', latitude: '41.092175', longitude: '1.156711' },
        sameAs: [
          'https://twitter.com/Portaventura_ES',
          'https://www.facebook.com/portaventuraoficial',
          'https://www.youtube.com/user/PortAventuraTV',
          'https://www.instagram.com/portaventuraworld',
          'https://es.wikipedia.org/wiki/PortAventura_World',
        ],
        image: data.imgRich || image,
        priceRange: 'EUR',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            availableLanguage: ['Spanish', 'English', 'French', 'German'],
          },
        ],
      };
      break;
    case 'store_park':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Store',
        name: data.og_title,
        telephone: data.phone || '+3490222220',
        description: data.og_description,

        url: `https://www.portaventuraworld.com${url}`,

        priceRange: 'EUR',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },

        openingHoursSpecification: {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          opens: '10:00',
          closes: '23:55',
        },

        parentOrganization: {
          '@type': 'AmusementPark',
          name: 'PortAventura World',
          image: data.imgRich || image,
          url: 'https://www.portaventuraworld.com/',
          telephone: data.phone || '+3490222220',
          email: 'info@portaventura.es',
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Vila-seca',
            addressRegion: 'Tarragona',
            addressCountry: 'Spain',
            postalCode: '43480',
            streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
          },
          geo: { '@type': 'GeoCoordinates', latitude: '41.092175', longitude: '1.156711' },
          openingHours: 'Mo-Fr 10:00-19:00,Sa-Su:10:00-20:00',
          sameAs: [
            'https://twitter.com/Portaventura_ES',
            'https://www.facebook.com/portaventuraoficial',
            'https://www.youtube.com/user/PortAventuraTV',
            'https://www.instagram.com/portaventuraworld',
            'https://es.wikipedia.org/wiki/PortAventura_World',
          ],
          priceRange: 'EUR',
        },
        image: data.imgRich || image,
      };
      break;
    case 'hotel':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Hotel',
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,

        priceRange: 'EUR',
        email: 'info@portaventura.es',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },

        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'customer service',
          },
        ],

        containedInPlace: {
          '@type': 'AmusementPark',
          name: 'PortAventura World',
          telephone: '+34902202220',
          priceRange: 'EUR',
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Vila-seca',
            addressRegion: 'Tarragona',
            addressCountry: 'Spain',
            postalCode: '43480',
            streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
          },
        },
      };
      break;
    case 'offer':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: data.og_title,
        image: data.imgRich || image,
        description: data.og_description,
        brand: 'PortAventura World',
        offers: {
          '@type': 'Offer',
          priceCurrency: 'EUR',
          price: data.price,
          priceValidUntil: data.date_valid_until,
          availability: 'http://schema.org/LimitedAvailability',
          url: `https://www.portaventuraworld.com${url}`,
        },
      };
      break;
    case 'event':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: data.og_title,
        startDate: data.date_valid_start,
        endDate: data.date_valid_until,
        image: data.imgRich || image,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,
        performer: {
          '@type': 'AmusementPark',
          name: 'PortAventura Park',
          image: data.imgRich || image,
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Vila-seca',
            addressRegion: 'Tarragona',
            addressCountry: 'Spain',
            postalCode: '43480',
            streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
          },
          telephone: '+34902202220',
          priceRange: 'EUR',
        },
        location: {
          '@type': 'Place',
          name: 'PortAventura Park',
          hasMap: 'https://www.google.es/maps/place/PortAventura+Park/',
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Vila-seca',
            addressRegion: 'Tarragona',
            addressCountry: 'Spain',
            postalCode: '43480',
          },
        },
      };
      break;
    case 'list_event':
      richMark = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,
        image: data.imgRich || image,
        numberOfItems: '15',
        // Por Aca
      };
      break;
    case 'empty':
      richMark = undefined;
      break;
    default:
      richMark = {
        '@context': 'https://schema.org',
        '@type': data.rich_markup_type,
        name: data.og_title,
        description: data.og_description,
        url: `https://www.portaventuraworld.com${url}`,
        image: data.imgRich || image,
        priceRange: 'EUR',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Vila-seca',
          addressRegion: 'Tarragona',
          addressCountry: 'Spain',
          postalCode: '43480',
          streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
        },
      };
  }

  if (data.inside_place) {
    console.log('WARN, inside_place that I dont know how to handle', data.rich_markup);
    // richMark = Object.assign(richMark, {
    //   containedInPlace: {
    //     '@type': 'Hotel',
    //     name: hotel_id,
    //     image: data.imgRich || image,
    //     priceRange: 'EUR',
    //     phone: data.edges[0].node.rich_markup.phone,
    //     address: {
    //       '@type': 'PostalAddress',
    //       addressLocality: 'Barcelona',
    //       addressRegion: 'Barcelona',
    //       postalCode: '43480',
    //       streetAddress: 'Avinguda Alcalde Pere Molas, s/n',
    //     },
    //   },
    // });
  }

  return { __html: JSON.stringify(richMark) };
};

export default getRichMark;
