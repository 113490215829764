import Img from 'gatsby-image/withIEPolyfill';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Slider from 'react-slick';
import Icon from '../../../helpers/icon';
import tt from '../../../helpers/translation';
import helaIcons from '../hela-icons';
import HotelCard from '../HelaCard/HelaCard';
import buildFunnelUrl from '../../../helpers/buildFunnelUrl';
import './helaImage.scss';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        zIndex: 100,
        right: '20px',
      }}
      onClick={onClick}
    >
      <div className="next-icon-item">
        <Icon icon="chevron-right" width="22" height="22" iconSet={helaIcons} />
      </div>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        zIndex: 100,
        left: '20px',
      }}
      onClick={onClick}
    >
      <div className="prev-icon-item">
        <Icon icon="chevron-left" width="22" height="22" iconSet={helaIcons} />
      </div>
    </div>
  );
};

class HelaImage extends Component {
  makeImageArray = (images, locale) =>
    images.map((image) => (
      <div key={image}>
        <a href={buildFunnelUrl(locale, 'hotels')} className="t-clickable-image">
          <Img className="img-fullwidth" fluid={image} alt="hotel-img" />
        </a>
      </div>
    ));

  render() {
    const {
      hotel: { images, price, hotelCardData },
      locale,
    } = this.props;

    const prepareForPrice = (value) => {
      const priceFinale = parseInt(value, 10);
      return priceFinale;
    };

    const settings = {
      customPaging: () => <div className="sliderHotel-page-item" />,
      dots: true,
      dotsClass: 'slick-dots',
      infinite: false,
      speed: 5,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow onClick={this.onClick} />,
      prevArrow: <SamplePrevArrow onClick={this.onClick} />,
      cssEase: 'linear',
    };
    return (
      <div className="BoxWithSliderAndChevron-main">
        {price && (
          <div className="price-item-container">
            <div className="triangle-right">
              <span className="first-item-tag">{tt('desde', this.props.intl.locale)}</span>
              <span className="price-item">{prepareForPrice(price)} €</span>
              <span className="second-item">{tt('persona/noche', this.props.intl.locale)}</span>
            </div>
          </div>
        )}
        <Slider {...settings}>{this.makeImageArray(images, this.props.intl.locale)}</Slider>
        <HotelCard hotelCardData={hotelCardData} locale={locale} />
      </div>
    );
  }
}

export default injectIntl(HelaImage);
