import './freya.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import MediaQuery from 'react-responsive';
import { injectIntl } from 'react-intl';
import Icon from '../../helpers/icon';
import { FreyaCard } from './FreyaCard';
import pawSliderIcons from '../PawPanel/paw-slider-icons';
import tt from '../../helpers/translation';

function SampleNextArrow(props) {
  const { className, style, onClick } = props //eslint-disable-line
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        right: '20px',
        paddingLeft: '5px',
      }}
      onClick={onClick}
    >
      <Icon
        icon="chevron-right"
        width="30"
        height="30"
        class="icon-arrow"
        iconSet={pawSliderIcons}
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props //eslint-disable-line
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        left: '20px',
      }}
      onClick={onClick}
    >
      <Icon
        icon="chevron-left"
        width="30"
        height="30"
        class="icon-arrow"
        iconSet={pawSliderIcons}
      />
    </div>
  );
}

export const Freya = injectIntl(props => {
  const {
    intl,
    data: { cards, isSlider, uniqueid },
    isHardCoded,
  } = props;

  const settingsLong = {
    /**
     * Custom paging:
     * Se utiliza para limitar el numero de bolitas,
     * en caso de tener mas de un slider por pàgina nos da problemas,
     * de manera que hemos añadido como ID en el WindosStar el slideCount.
     * Pero esto solo funciona de manera temporal, ya que si tuvieramos dos sliders con el mismo numero de items,
     * nos encontrariamos con el mismo problema.
     * De manera que deberemos mejorar esta funcion en un futuro cercano.
     *
     * TO DO!!
     */

    customPaging(i) {
      //

      const { currentSlide, slideCount } = this;
      const window = 5;
      let windowStart = 0;

      if (currentSlide === 0) {
        if (i === 0) {
          localStorage.setItem(`windowStart${slideCount}`, windowStart);
        }
      } else {
        windowStart = parseInt(localStorage.getItem(`windowStart${slideCount}`));
      }

      if (currentSlide - windowStart === 5) {
        windowStart += 1;
        if (windowStart + window === slideCount) windowStart -= 1;
        localStorage.setItem(`windowStart${slideCount}`, windowStart);
      }
      if (currentSlide - windowStart === 0) {
        windowStart -= 1;
        if (windowStart < 0) windowStart = 0;
        localStorage.setItem(`windowStart${slideCount}`, windowStart);
      }
      if (i > windowStart + window) return <span className="spam" sytle="display:none" />;
      if (i < windowStart) return <span className="spam" sytle="display:none" />;

      if (i === currentSlide) {
        return <div className="slick_active-dot" />;
      }
      return <div className="slick_disabled" />;
    },

    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 1,
    speed: 500,
    dots: true,
    lastSlide: 0,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const settings = {
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    dotsClass: 'dots-class',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  const settingsHi = {
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    dots: true,
    dotsClass: 'dots-class',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingsMed = {
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 2.5,
    speed: 500,
    dots: true,
    dotsClass: 'dots-class',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsLow = {
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 2.1,
    speed: 500,
    dots: true,
    dotsClass: 'dots-class',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingsUltraLow = {
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 1.6,
    speed: 500,
    dots: true,
    dotsClass: 'dots-class',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const cardMap = array => {
    if (array.length) {
      return array.map(element => (
        <FreyaCard
          isHardCoded={isHardCoded}
          data={element}
          key={element.title}
          twoButton={element.twoButton}
        />
      ));
    }
    return <div>{tt('No hay elementos que coincidan con tus filtros.', intl.locale)}</div>;
  };

  const isBrowserTrue = <div className="freya-container">{cardMap(cards)}</div>;

  const isInSlider = (
    <div className="freya-container">
      {cards.length === 1 ? (
        <FreyaCard
          isHardCoded={isHardCoded}
          data={cards[0]}
          key={cards[0].title}
          twoButton={cards[0].twoButton}
        />
      ) : (
        <>
          <MediaQuery minWidth={768} maxWidth={900}>
            <Slider {...settingsUltraLow}>{cardMap(cards)}</Slider>
          </MediaQuery>
          <MediaQuery minWidth={900} maxWidth={1030}>
            <Slider {...settingsLow}>{cardMap(cards)}</Slider>
          </MediaQuery>
          <MediaQuery minWidth={1030} maxWidth={1200}>
            <Slider {...settingsMed}>{cardMap(cards)}</Slider>
          </MediaQuery>
          <MediaQuery minWidth={1200}>
            <Slider {...settingsHi}>{cardMap(cards)}</Slider>
          </MediaQuery>
        </>
      )}
    </div>
  );

  const isMobileTrue = (
    <div className="freya-container">
      {cards.length === 1 ? (
        <FreyaCard
          isHardCoded={isHardCoded}
          data={cards[0]}
          key={cards[0].title}
          twoButton={cards[0].twoButton}
        />
      ) : cards.length > 10 ? (
        <Slider {...settingsLong}> {cardMap(cards)} </Slider>
      ) : (
        <Slider {...settings}> {cardMap(cards)} </Slider>
      )}
    </div>
  );

  return (
    <div className="freya">
      {!isSlider ? (
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobileTrue} tablet={isInSlider} desktop={isBrowserTrue} />
        </MediaServerRender>
      ) : (
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobileTrue} tablet={isInSlider} desktop={isInSlider} />
        </MediaServerRender>
      )}
    </div>
  );
});

Freya.propTypes = {
  data: PropTypes.object.isRequired,
  isHardCoded: PropTypes.bool,
  isSlider: PropTypes.bool,
};
