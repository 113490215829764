import React from 'react';
import PropTypes from 'prop-types';
import './TitleH3.scss';

const TitleH3 = props => {
  const {
    titleAlone,
    data: { title, description, linkItem, linkUrl, h4 },
  } = props;
  let hasSubtitle;
  let hasLinkItem;

  if (linkItem) {
    hasLinkItem = <div className="link-item">{linkItem && <a href={linkUrl}>{linkItem}</a>}</div>;
  }
  if (h4) {
    hasSubtitle = (
      <h4 dangerouslySetInnerHTML={{ __html: description }} className="TitleH3-subtitle" />
    );
  } else if (description) {
    hasSubtitle = (
      <p dangerouslySetInnerHTML={{ __html: description }} className="TitleH3-subtitle" />
    );
  }

  return (
    <div className="TitleH3-container">
      {titleAlone ? (
        <h3 dangerouslySetInnerHTML={{ __html: titleAlone }} className="TitleH3-title" />
      ) : (
        <h3 dangerouslySetInnerHTML={{ __html: title }} className="TitleH3-title" />
      )}
      {hasSubtitle}
      {hasLinkItem}
      {/* children */}
    </div>
  );
};

TitleH3.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    linkItem: PropTypes.string,
  }),
};

TitleH3.defaultProps = {
  data: {
    title: 'TITULO STANDARD',
  },
};

export default TitleH3;
