import './FreyaCard.scss';

import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import Buttons from '../Buttons/Buttons';
import { Fenrir } from '../Fenrir/Fenrir';
import Icon from '../../helpers/icon';
import RatingStars from '../RatingStars/RatingStars';
import iconSet from './freyacard-icons';

export const FreyaCard = (props) => {
  const {
    data: {
      downloadType,
      altImage,
      image,
      imgsize,
      disabled,
      title,
      titleType,
      description,
      seeMore,
      summary,
      located,
      twoButton,
      stars,
      download,
      popupType,
      buttons: { cta, size, color, ctaText, cta2, size2, color2, ctaText2, popup, blank2, blank1 },
      fenrirData,
      logo,
    },
    isHardCoded,
  } = props;
  const [showPopup, setshowPopup] = useState(false);
  const handlePopups = () => {
    setshowPopup(!showPopup);
  };
  let isDescriptionSeeMore;
  if (seeMore || description) {
    isDescriptionSeeMore = (
      <span className="subtitle-card">
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {/* {seeMore} */}
      </span>
    );
  }

  let isSummary;
  if (summary) {
    isSummary = (
      <span className="summary-card">
        <div dangerouslySetInnerHTML={{ __html: summary }} />
      </span>
    );
  }

  let isLocation;
  if (located) {
    isLocation = (
      <div className="subtitle-card location-freya">
        <Icon icon="location_on" width="22" height="22" color="black" iconSet={iconSet} />
        <span>{located}</span>
      </div>
    );
  }

  let isStars;

  if (stars) {
    isStars = (
      <div className="react-stars">
        <RatingStars count={stars} color="#5e5e5e" />
      </div>
    );
  }

  let isTwoButton;
  if (twoButton) {
    isTwoButton = (
      <div className="button-cta">
        <Buttons link={cta} size={size} color={color} text={ctaText} blank={blank1} />
        {ctaText2 && (
          <Buttons link={cta2} size={size2} color={color2} text={ctaText2} blank={blank2} />
        )}
      </div>
    );
  } else if (popup) {
    isTwoButton = <Buttons onClick={handlePopups} size={size} color={color} text={ctaText} />;
  } else if (cta === false) {
    isTwoButton = undefined;
  } else {
    isTwoButton = (
      <div className="button-cta">
        <Buttons link={cta} size={size} color={color} text={ctaText} blank={blank1} />
      </div>
    );
  }

  if (popupType) {
    isTwoButton = (
      <div className="button-cta">
        <Buttons link={cta} size={size} color={color} text={ctaText} />
        <Buttons onClick={handlePopups} size={size2} color={color2} text={ctaText2} />
      </div>
    );
  }

  let isPopupTrue;
  if (showPopup === true) {
    isPopupTrue = (
      <div className="freya-popup">
        <div className="popup-close" onClick={handlePopups}>
          X
        </div>
        <div className="popup-title">{popup.titlepop}</div>
        <div className="popup-text">
          <div dangerouslySetInnerHTML={{ __html: popup.textpop }} />
        </div>
      </div>
    );
  }

  let isFenrir;
  if (fenrirData) {
    isFenrir = <Fenrir data={fenrirData} />;
  }

  let isLogo;
  if (logo) {
    isLogo = (
      <div className="rounded-logo">
        <img src={logo} alt="logo-hotel" />
      </div>
    );
  }

  let isDownload;
  if (download) {
    isDownload = (
      <div className="download-content">
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <a href={download}>
          <Icon icon="get_app" width="30" height="30" class="icon-arrow" iconSet={iconSet} />
        </a>
      </div>
    );
  }
  if (downloadType) {
    return (
      <div className={`card-body-${imgsize || 'big'}`}>
        <div className={`img-card-${imgsize}`}>
          {isHardCoded ? <img src={image} className="img-rounded" alt="header" /> : null}
          {!isHardCoded ? <Img fluid={image} alt={altImage || 'header'} /> : null}
        </div>
        {isLogo}
        <div className={`text-card ${showPopup}`}>
          <div className="text-inside-card">
            <div className="title-card">
              {titleType === 'h3' ? <h3>{title}</h3> : <h4>{title}</h4>}
            </div>
            {isStars}
            {isDownload}
            {isSummary}
          </div>
          <div>{isTwoButton}</div>
        </div>
        {isFenrir}
        {isPopupTrue}
      </div>
    );
  }

  return (
    <div className={`card-body-${imgsize || 'big'} ${disabled ? 'cardDisabled' : null}`}>
      <div className={`img-card-${imgsize}`}>
        {isHardCoded ? <img src={image} className="img-rounded" alt="header" /> : null}
        {!isHardCoded ? <Img fluid={image} alt={altImage || 'header'} /> : null}
      </div>
      {isLogo}
      <div className={`text-card ${showPopup}`}>
        <div className="text-inside-card">
          {titleType === 'h3' ? (
            <h3 className="title-card">{title}</h3>
          ) : (
            <h4 className="title-card">{title}</h4>
          )}
          {isStars}
          {isLocation}
          {isDescriptionSeeMore}
          {isDownload}
          {isSummary}
        </div>
        {disabled ? null : isTwoButton}
      </div>
      {isFenrir}
      {isPopupTrue}
    </div>
  );
};

FreyaCard.propTypes = {
  data: PropTypes.object,
  isHardCoded: PropTypes.bool,
};

FreyaCard.defaultProps = {
  data: {
    image:
      'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/008/596/original/servicios-hotel-colorado-creek-600x600.jpg',
    title: 'Freya Title',
    description: 'Lore Ipsum Freya Descriptium',
    seeMore: '/entradas',
    imgsize: 'promoted',
    located: 'Far West',
    buttons: {
      cta: '/entradas',
      size: 'alone',
      color: 'orange',
      ctaText: 'RESERVAR',
    },
  },
};
