import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../src/helpers/seo';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hela from '../src/components/Hela/Hela';
import Hod from '../src/components/Hod/Hod';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';

import './campings_asociados.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query camping_asociados($locale: String!) {
    allCampingsAsocHotelesSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allCampingsAsocTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allCampingsAsocImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCampingsAsocBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allCampingsAsocBigParagraph(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          first_paragraph
          second_paragraph
          third_paragraph
        }
      }
    }
    allCampingsAsocHotelesBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          title_path
          description
          stars
          localImage {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCampingsAsocCampingsBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          characteristics
          localImage {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCampingsAsocPromotedSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allCampingsAsocRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class CampingsAsociados extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allCampingsAsocTitleHeader.edges[0].node.title,
      subtitle: this.props.data.allCampingsAsocTitleHeader.edges[0].node.description,
      image: {
        url: this.props.data.allCampingsAsocImageHeader.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allCampingsAsocImageHeader.edges[0].node.alt,
      },
    };

    const titleData = {
      title: tt('HOTELES ASOCIADOS', this.props.pageContext.locale),
    };

    const prepareforHela = (src) => ({
      hotels: src.edges.map((e, i) => ({
        hotelCardData: {
          title: e.node.title,
          description: e.node.description,
          id: String(i),
          stars: e.node.stars,
        },
        images: [e.node.localImage.childImageSharp.fluid],
      })),
    });

    const titleData2 = {
      title: tt('CAMPINGS ASOCIADOS', this.props.pageContext.locale),
    };

    const prepareforHela2 = (src) => ({
      hotels: src.edges.map((e, i) => ({
        hotelCardData: {
          title: e.node.title,
          description: e.node.description + e.node.characteristics,
        },
        images: [e.node.localImage.childImageSharp.fluid],
      })),
    });

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allCampingsAsocPromotedSubBlock.edges.map((promotion) => ({
        image: promotion.node.localImage.childImageSharp.fluid,
        altImage: promotion.node.image_alt,
        title: promotion.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: promotion.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: promotion.node.ctaText,
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allCampingsAsocHotelesSeoBlock.edges[0].node._0.title}
          description={this.props.data.allCampingsAsocHotelesSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="asociados-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allCampingsAsocBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allCampingsAsocRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allCampingsAsocImageHeader.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <div className="BigParagraph-camping">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allCampingsAsocBigParagraph.edges[0].node
                      .first_paragraph,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allCampingsAsocBigParagraph.edges[0].node
                      .second_paragraph,
                  }}
                />
              </div>
              <TitleH3 data={titleData} />
              <Hela
                locale={this.props.pageContext.locale}
                data={prepareforHela(this.props.data.allCampingsAsocHotelesBlock)}
              />
              <TitleH3 data={titleData2} />
              <Hela
                locale={this.props.pageContext.locale}
                data={prepareforHela2(this.props.data.allCampingsAsocCampingsBlock)}
              />
              <div className="BigParagraph-camping">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allCampingsAsocBigParagraph.edges[0].node
                      .third_paragraph,
                  }}
                />
              </div>
              <div className="promotions-content">
                <H4 title={freyaData.title} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CampingsAsociados;
